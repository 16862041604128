import "./navbar.css";
import img from '../assets/bsw.png';
import React, { useState } from 'react';
import { MdNotes } from "react-icons/md";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

export default class Navbar extends React.Component {

  state = {
    color: 'rgba(255, 255, 255, 0.45'
  }

  // Set color based on Scroll
  listenScrollEvent = _e => {
    if (window.scrollY >= 88) {
      this.setState({ color: 'rgba(255, 255, 255, 0.90)' })
    } else {
      this.setState({ color: 'rgba(255, 255, 255, 0.45' })
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listenScrollEvent)
  }

  render() {
    return (
      // <header style={{ background: this.state.color }}>  This is for change of color on scroll
      <header class="navigationHeader">
        <div class="navigationContainer">
          <NavItem icon={<MdNotes className="actualIcon" />}>
            <DropdownMenu></DropdownMenu>
          </NavItem>
          <div class="headerLogoSection">
            <div id="headerRow">
              <a href="." id="logo"><img class="logoText" src={img} alt="" /></a>
            </div>
          </div>
          <NavigationList />
          <div class='languageSection'>
            <DropdownLanguage />
          </div>
        </div>
      </header>
    )
  }
}

function NavigationList() {
  const { t } = useTranslation()

  return (
    <nav>
      <ul>
        <li class="navClick default-color"><a href="#about">{t('about')}</a></li>
        <li class="navClick default-color"><a href="">{t('services')}</a></li>
        <li class="navClick default-color"><a href="">{t('relations')}</a></li>
        <li class="navClick default-color"><a href="">{t('project')}</a></li>
        <li class="navClick default-color"><a href="https://bsw-epc.com/careers/">{t('recruitment')}</a></li>
        <li class="navClick default-color"><a href="#contact">{t('contact')}</a></li>
      </ul>
    </nav>
  );
}

function DropdownLanguage() {
  const languages = [
    {
      code: 'en',
      name: 'English',
      country_code: 'us'
    },
    {
      code: 'es',
      name: 'Español',
      country_code: 'es',
    },
  ]

  return (
    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
      {languages.map(({ code, country_code }) => (
        <li key={country_code}>
          <button className="dropdownLanguage" onClick={() => i18next.changeLanguage(code)}>
            <span className={`flag-icon flag-icon-${country_code} mx-2`}></span>
          </button>
        </li>
      ))}
    </ul>
  );
}

function NavItem(props) {
  const [open, setOpen] = useState(false);

  return (
    <li className="smallScreenNav">
      <button className="icon-button" onClick={() => setOpen(!open)}>
        {props.icon}
      </button>
      {open && props.children}
    </li>
  );
}

function DropdownMenu() {
  const { t } = useTranslation()

  return (
    <div className="dropdown">
      <div className="menu">
        <a href='#about' className="menu-item">{t('about')}</a>
        <a href='https://bsw-epc.com/careers/' className="menu-item">{t('recruitment')}</a>
        <a href='#contact' className="menu-item">{t('contact')}</a>
      </div>
    </div>
  );
} 