import "./footer.css";
import React from 'react'
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation()
  return (
    <div id="footer" className="container">
      <div className="footer-section">
        <ul>
          <li className="rightBorderFooter">© {(new Date().getFullYear())} BW Industrial Construction</li>
          <li className="rightBorderFooter"><a href="">Accessibility</a></li>
          <li className="rightBorderFooter"><a href="">Privacy Policy</a></li>
          <li><a href="https://bsw-epc.com/admin/">Admin Login</a></li>
        </ul>
      </div>
    </div>
  );
}

export default Footer;
